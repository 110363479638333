export const routes = [
  '/',
  '/admin',
  '/admin/account',
  'admin/credits',
  '/admin/:page?',
  '/admin/application-list',
  '/admin/domain-list',
  '/admin/integrations',
  '/admin/sidekiq',
  '/admin/tank',
  '/admin/tenants',
  '/admin/tenants/:id/:page?',
  '/admin/tenants/new',
  '/admin/users',
  '/admin/users/:id',
  '/admin/users/:id/edit',
  '/admin/users/new',
  '/applications',
  '/applications/:appId/:cycleId?',
  '/applications/:applicationId/screens/:screenSignature/:cycleId?',
  '/client-dashboard',
  '/client-dashboard/:id',
  '/cycles',
  '/flow-graph',
  '/flow-graph/:processId',
  '/dashboard',
  '/diagrams/:id',
  '/diagrams/new',
  '/ds',
  '/event-log-exports',
  '/events',
  '/events/:observerIds?',
  '/instances-viewer',
  '/instances-viewer/:id?',
  '/mining',
  '/observers',
  '/observers/:id',
  '/pdds',
  '/process-boundary/:reportId',
  '/process-explorer',
  '/process-explorer/:id?',
  '/processes',
  '/processes/:id',
  '/processes/:processId/state_actions/:state',
  '/review',
  '/review/:observerID/:reviewID/:eventAnnotationID',
  '/review/:observerID/:reviewID/new',
  '/review/:observerID/new',
  '/review/:observerID?',
  '/roles',
  '/sandbox',
  '/screenshot-annotator',
  '/screenshot/:eventLogId',
  '/signature-service-jobs',
  '/status',
  '/support',
  '/templates',
  '/templates/new',
  '/visualizations',
  '/visualizations',
  '/visualizations/applications-pie',
  '/visualizations/butterfly',
  '/visualizations/butterfly/:id?',
  '/web-applications',
]

export const actions = [
  '/admin/tenant/create',
  '/butterfly:create-process',
  '/butterfly:graphProcess_select',
  '/client-dashboard:open-admin-menu',
  '/client-dashboard/:id:delete-process-card',
  '/client-dashboard/:id:detail-menu',
  '/client-dashboard/:id:edit-name',
  '/events:delete',
  '/instances-viewer:annotate',
  '/instances-viewer:navigate-all-instances',
  '/observer:configure-neo',
  '/observer:toggle-neo',
  '/process-explorer:create-subprocess',
  '/process-explorer:edit-process-name',
  '/process-explorer:navigate-all-processes',
  '/process-explorer:node-controls',
  '/process-explorer:save-as-new-process',
  '/processes/:id:delete',
  '/processes/:id:planned-steps',
  '/processes/:id:steps',
  '/signature-service-jobs/:view-job-status',
  '/tenants:set-fiq-lite',
  '/tenants:see-fiq-lite-toggle',
]

export const liteRules = {
  routes: [
    '/',
    '/admin',
    '/admin/account',
    '/admin/credits',
    '/admin/:page?',
    '/admin/integrations',
    '/admin/users',
    '/admin/users/:id',
    '/admin/users/:id/edit',
    '/admin/users/new',
    '/admin/application-list',
    '/admin/domain-list',
    '/applications',
    '/client-dashboard',
    '/client-dashboard/:id',
    '/cycles',
    '/flow-graph',
    '/flow-graph/:processId',
    '/dashboard',
    '/diagrams/:id',
    '/diagrams/new',
    '/event-log-exports',
    '/events',
    '/events/:observerIds?',
    '/instances-viewer',
    '/instances-viewer/:id?',
    '/mining',
    '/observers',
    '/observers/:id',
    '/pdds',
    '/process-explorer',
    '/process-explorer/:id?',
    '/processes',
    '/processes/:id',
    '/processes/:processId/state_actions/:state',
    '/screenshot/:eventLogId',
    '/status',
    '/support',
    '/visualizations',
    '/visualizations/applications-pie',
    '/visualizations/butterfly',
    '/visualizations/butterfly/:id?',
    '/web-applications',
  ],
  actions: [
    '/butterfly:create-process',
    '/butterfly:graphProcess_select',
    '/client-dashboard/:id:delete-process-card',
    '/client-dashboard/:id:detail-menu',
    '/client-dashboard/:id:edit-name',
    '/client-dashboard:open-admin-menu',
    '/instances-viewer:annotate',
    '/instances-viewer:navigate-all-instances',
    '/observer:configure-neo',
    '/observer:toggle-neo',
    '/process-explorer:create-subprocess',
    '/process-explorer:edit-process-name',
    '/process-explorer:navigate-all-processes',
    '/process-explorer:node-controls',
    '/process-explorer:save-as-new-process',
    '/processes/:id:delete',
    '/processes/:id:planned-steps',
    '/processes/:id:steps',
    '/tenants:see-fiq-lite-toggle',
    '/tenants:set-fiq-lite',
  ],
  tenantActions: {
    createTenants: ['admin', 'user', 'limited'],
  },
}

export const rules = {
  limited: {
    routes: [
      '/client-dashboard',
      '/client-dashboard/:id',
      '/instances-viewer/:id?',
      '/process-explorer/:id?',
      '/support',
      '/visualizations',
      '/visualizations/butterfly/:id?',
    ],
  },
  virtual_interviewer: {
    routes: [
      '/review',
      '/review/:observerID/:reviewID/:eventAnnotationID',
      '/review/:observerID/:reviewID/new',
      '/review/:observerID/new',
      '/review/:observerID?',
      '/support',
    ],
  },
  user: {
    routes: [
      '/',
      '/client-dashboard',
      '/client-dashboard/:id',
      '/flow-graph',
      '/flow-graph/:processId',
      '/dashboard',
      '/diagrams/:id',
      '/diagrams/new',
      '/event-log-exports',
      '/events',
      '/events/:observerIds?',
      '/instances-viewer',
      '/instances-viewer/:id?',
      '/mining',
      '/observers',
      '/observers/:id',
      '/pdds',
      '/process-boundary/:reportId',
      '/process-explorer',
      '/process-explorer/:id?',
      '/processes',
      '/processes/:id',
      '/processes/:processId/state_actions/:state',
      '/screenshot/:eventLogId',
      '/status',
      '/support',
      '/visualizations',
      '/visualizations/butterfly',
      '/visualizations/butterfly/:id?',
    ],
    actions: [
      '/process-explorer:create-subprocess',
      '/process-explorer:edit-process-name',
      '/process-explorer:navigate-all-processes',
      '/process-explorer:save-as-new-process',
      '/process-explorer:node-controls',
      '/instances-viewer:navigate-all-instances',
      '/instances-viewer:annotate',
      '/butterfly:create-process',
      '/butterfly:graphProcess_select',
      '/client-dashboard/:id:delete-process-card',
      '/client-dashboard/:id:detail-menu',
      '/client-dashboard:open-admin-menu',
      '/client-dashboard/:id:edit-name',
    ],
  },
  power: {
    routes: [
      '/',
      '/applications',
      '/applications/:applicationId/screens/:screenSignature/:cycleId?',
      '/applications/:appId/:cycleId?',
      '/client-dashboard',
      '/client-dashboard/:id',
      '/flow-graph',
      '/flow-graph/:processId',
      '/dashboard',
      '/diagrams/:id',
      '/diagrams/new',
      '/event-log-exports',
      '/events',
      '/events/:observerIds?',
      '/instances-viewer',
      '/instances-viewer/:id?',
      '/mining',
      '/observers',
      '/observers/:id',
      '/pdds',
      '/process-boundary/:reportId',
      '/process-explorer',
      '/process-explorer/:id?',
      '/processes',
      '/processes/:id',
      '/processes/:processId/state_actions/:state',
      '/review',
      '/review/:observerID/:reviewID/:eventAnnotationID',
      '/review/:observerID/:reviewID/new',
      '/review/:observerID/new',
      '/review/:observerID?',
      '/screenshot/:eventLogId',
      '/signature-service-jobs',
      '/status',
      '/support',
      '/visualizations',
      '/visualizations/butterfly',
      '/visualizations/butterfly/:id?',
      '/web-applications',
    ],
    actions: [
      '/process-explorer:create-subprocess',
      '/process-explorer:edit-process-name',
      '/process-explorer:navigate-all-processes',
      '/process-explorer:save-as-new-process',
      '/process-explorer:node-controls',
      '/instances-viewer:navigate-all-instances',
      '/instances-viewer:annotate',
      '/butterfly:create-process',
      '/butterfly:graphProcess_select',
      '/client-dashboard/:id:delete-process-card',
      '/client-dashboard/:id:detail-menu',
      '/client-dashboard:open-admin-menu',
      '/client-dashboard/:id:edit-name',
    ],
  },
  admin: {
    routes: [
      '/',
      '/admin',
      '/admin/account',
      '/admin/credits',
      '/admin/:page?',
      '/admin/integrations',
      '/admin/users',
      '/admin/users/:id',
      '/admin/users/:id/edit',
      '/admin/users/new',
      '/admin/application-list',
      '/admin/domain-list',
      '/applications',
      '/applications/:applicationId/screens/:screenSignature/:cycleId?',
      '/applications/:appId/:cycleId?',
      '/client-dashboard',
      '/client-dashboard/:id',
      '/cycles',
      '/flow-graph',
      '/flow-graph/:processId',
      '/dashboard',
      '/diagrams/:id',
      '/diagrams/new',
      '/ds',
      '/event-log-exports',
      '/events',
      '/events/:observerIds?',
      '/instances-viewer',
      '/instances-viewer/:id?',
      '/mining',
      '/observers',
      '/observers/:id',
      '/pdds',
      '/process-boundary/:reportId',
      '/process-explorer',
      '/process-explorer/:id?',
      '/processes',
      '/processes/:id',
      '/processes/:processId/state_actions/:state',
      '/review',
      '/review/:observerID/:reviewID/:eventAnnotationID',
      '/review/:observerID/:reviewID/new',
      '/review/:observerID/new',
      '/review/:observerID?',
      '/roles',
      '/sandbox',
      '/screenshot-annotator',
      '/screenshot/:eventLogId',
      '/signature-service-jobs',
      '/status',
      '/support',
      '/visualizations',
      '/visualizations/applications-pie',
      '/visualizations/butterfly',
      '/visualizations/butterfly/:id?',
      '/web-applications',
    ],
    actions: [
      '/butterfly:create-process',
      '/butterfly:graphProcess_select',
      '/client-dashboard/:id:delete-process-card',
      '/client-dashboard/:id:detail-menu',
      '/client-dashboard/:id:edit-name',
      '/client-dashboard:open-admin-menu',
      '/instances-viewer:annotate',
      '/instances-viewer:navigate-all-instances',
      '/observer:configure-neo',
      '/observer:toggle-neo',
      '/process-explorer:create-subprocess',
      '/process-explorer:edit-process-name',
      '/process-explorer:navigate-all-processes',
      '/process-explorer:node-controls',
      '/process-explorer:save-as-new-process',
      '/processes/:id:delete',
      '/processes/:id:planned-steps',
      '/processes/:id:steps',
      '/tenants:see-fiq-lite-toggle',
    ],
  },
  superuser: { routes: routes, actions: actions },
}
