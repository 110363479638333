/* eslint-disable no-console */
import * as d3 from 'd3'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import unionWith from 'lodash/unionWith'
import { toJS } from 'mobx'
import { graphColors } from '@fortressiq/fiq-ds'

import dom from 'lib/Dom'
import { toAlpha } from 'lib/String'
import { getTimeElapsed } from 'lib/Time'

import store from '../stores/processExplorerStore'
import subprocessStore from '../stores/subprocessStore'

import renderBaseNodes from './renderers/BaseNodeRenderer'
import renderBuildGroup from './renderers/BuildGroupRenderer'
import renderFlows from './renderers/FlowRenderer'
import renderGroups from './renderers/GroupRenderer'
import renderLabels from './renderers/LabelRenderer'
import renderLinks from './renderers/LinkRenderer'
import renderNodes from './renderers/NodeRenderer'

import DropShadowRenderer from './renderers/DropShadowRenderer'

export default class ProcessExplorerTree {
  constructor(props) {
    const { data, history, clearActivityPanel, selectNode, flowData } = props

    this.maxWeight = data.value //max edge weight in actual value
    this.maxPathWidth = 32 //max visual width of edge
    this.width = dom.windowWidth()
    this.height = dom.windowHeight()
    this.margin = { top: 90, right: 350, bottom: 90, left: 90 } /* margin on botton/right for activity panel */
    this.history = history

    this.svg = null
    this.svgCanvas = null
    this.subSvg = null
    this.flowSvg = null
    this.diagramData = data
    this.diagramState = data
    this.loadSubId = null
    this.clearActivityPanel = clearActivityPanel
    this.selectNode = selectNode

    this.tree = null
    this.rootNode = null
    this.subId = null

    this.groupClickCount = 0
    this.groupInMem = null
    this.groups = []

    this.flowData = flowData

    //stores all collapsed nodes -- so that any transforms include previously collapsed nodes
    this.collapsed = {}

    this.maxDepth = 0

    this.nodeHPadding = 100

    this.initTree()
  }

  setCanvasDimensions(nodes) {
    let minYCoord = 0
    let maxYCoord = 0

    nodes.forEach(d => {
      if (Math.sign(d.x) === -1 && d.x < minYCoord) {
        minYCoord = d.x
      }
      if (Math.sign(d.x) === 1 && d.x > maxYCoord) {
        maxYCoord = d.x
      }

      if (d.depth > this.maxDepth) this.maxDepth = d.depth
    })

    const panel = document.getElementById('ActivityBox')
    let panelHeight = 0
    let panelWidth = 0
    if (panel) {
      panelHeight = panel.offsetHeight + 70 //height of activity controls panel -- do not want to cover diagram
      panelWidth = panel.offsetWidth + 70 //height of activity controls panel -- do not want to cover diagram
    }

    const viewWidth =
      this.maxDepth * (store.nodeWidth + this.nodeHPadding) +
      store.nodeWidth +
      this.margin.left +
      this.margin.right +
      panelWidth

    this.svgCanvas.attr('width', viewWidth)
    this.svg.attr('width', viewWidth)
    this.subSvg.attr('width', viewWidth)
    this.flowSvg.attr('width', viewWidth)
    this.groupsSvg.attr('width', viewWidth)

    const newHeightInt =
      maxYCoord + Math.abs(minYCoord) + store.nodeHeight * 4 + Math.max(panelHeight, this.margin.bottom)

    const newHeight = `${newHeightInt}px`
    this.svgCanvas.style('height', newHeight)
    this.svg.style('height', newHeight)
    this.subSvg.style('height', newHeight)
    this.flowSvg.style('height', newHeight)
    this.groupsSvg.style('height', newHeight)

    //y offset is y offset of the nodes on top of root, the tree will not always be balanced
    this.svg.attr('transform', `translate(${this.margin.left}, ${Math.abs(minYCoord) + this.margin.top})`)
    this.subSvg.attr('transform', `translate(${this.margin.left}, ${Math.abs(minYCoord) + this.margin.top})`)
    this.flowSvg.attr('transform', `translate(${this.margin.left}, ${Math.abs(minYCoord) + this.margin.top})`)
    this.groupsSvg.attr('transform', `translate(${this.margin.left}, ${Math.abs(minYCoord) + this.margin.top})`)
  }

  //takes into account that the tree starts at height / 2 and that the tree is balanced
  setCanvasFocus() {
    const containerEl = document.getElementById('diagramContainer')
    const contentEl = document.getElementById('mapAndDiagramContainer')
    const startNodeEl = document.getElementsByClassName('startNode')[0]

    if (!containerEl || !contentEl || !startNodeEl) return

    const contentElData = contentEl.getBoundingClientRect()
    const startNodeData = startNodeEl.getBoundingClientRect()
    const contentElOffset = contentElData.height + contentElData.top
    const startNodeTop = startNodeData.top
    const toScroll = startNodeTop - contentElOffset
    containerEl.scrollTop = toScroll
  }

  initTree() {
    const elem = document.getElementById('diagramSVG')
    if (elem) elem.parentNode.removeChild(elem)

    const svgCanvas = d3
      .select('#diagramSVGCanvas')
      .append('svg')
      .attr('id', 'diagramSVG')
      .style('margin-top', '50px')
      .style('min-height', '100%')

    const groupsSvg = svgCanvas
      .append('g')
      .attr('id', 'groupCanvas')
      .attr('transform', `translate(${this.margin.left}, ${this.height / 2})`)

    const svg = svgCanvas
      .append('g')
      .attr('id', 'canvasG')
      .attr('transform', `translate(${this.margin.left}, ${this.height / 2})`)

    const subSvg = svgCanvas
      .append('g')
      .attr('id', 'subCanvas')
      .attr('transform', `translate(${this.margin.left}, ${this.height / 2})`)

    const flowSvg = svgCanvas
      .append('g')
      .attr('id', 'flowCanvas')
      .attr('transform', `translate(${this.margin.left}, ${this.height / 2})`)

    this.svg = svg
    this.svgCanvas = svgCanvas
    this.subSvg = subSvg
    this.flowSvg = flowSvg
    this.groupsSvg = groupsSvg

    const nodeVMarginOffset = -60
    this.tree = d3.tree().nodeSize([store.nodeSizes.collapsed.height + nodeVMarginOffset, store.nodeWidth])

    const rootNode = d3.hierarchy(this.diagramData, d => d.children)
    this.rootNode = rootNode

    //initial coordinates of root node (ensures proper render animation)
    rootNode.x0 = this.height / 2
    rootNode.y0 = 0

    store.d3Data = rootNode
    let labelNum = 0
    this.tree(rootNode)
      .descendants()
      .forEach(d => {
        if (d.data.type === 'end') {
          const pathInfo = store.getPathInfo(d.data.id)
          d.pathKey = pathInfo.pathKey
          d.pathName = pathInfo.pathName
          d.pathId = pathInfo.pathId
          d.pathDuration = getTimeElapsed(Math.round(pathInfo.pathDuration))
        }
        if (d.data.type === 'decision') {
          d.labelNum = toAlpha(labelNum)
          labelNum += 1
        }

        //give each branch a height value/label
        if (d.children) {
          d.children.forEach((child, index) => {
            child.branchLabel = child.parent.labelNum ? `${child.parent.labelNum}${index}` : index
          })
        }
      })
    this.maxDepth = this.getMaxDepth(store.d3Data)

    this.update()
    this.setCanvasFocus()
  }

  ////////
  update(nodeSource) {
    const flow = toJS(store.flow)
    const source = nodeSource || store.d3Data

    const duration = 750
    let maxWeight = 0
    const data = this.tree(store.d3Data)
    const nodes = data.descendants()
    const links = data.descendants().slice(1)

    nodes.forEach(d => {
      d.y = d.depth * (store.nodeWidth + this.nodeHPadding)
      d.color = 'rgb(205, 222, 255)'
      d.flow = flow
      d.data.applications = d.data.activities

      //find path maxWeight (used to normalize weights)
      if (d.data.value > maxWeight) {
        maxWeight = d.data.value
      }

      //update node data
      if (d.data.type === 'end') {
        const pathInfo = store.getPathInfo(d.data.id)
        d.pathKey = pathInfo.pathKey
        d.pathName = pathInfo.pathName
        d.pathId = pathInfo.pathId
        d.pathDuration = getTimeElapsed(Math.round(pathInfo.pathDuration))
        d.hideDuration = pathInfo.hideDuration
      }

      //if flow loaded, position it's nodes, and pass relevant d3 information
      const flowIndex = flow.findIndex(id => id === d.data.id)
      if (flowIndex !== -1) {
        flow[flowIndex] = {
          ...d,
          d: d,
          id: flow[flowIndex],
          name: d.data.name,
          type: d.data.type,
          color: store.flowColor,
        }
      }
    })
    this.maxWeight = maxWeight
    this.setCanvasDimensions(nodes)

    //Update the nodes...
    const node = this.svg
      .selectAll('g.node')
      .data(nodes, d => {
        d.id = d.data.id
        return d.id
      })
      .attr('class', this.classForNode)

    // Enter any new nodes at the parent's previous position.
    const nodeEnter = node
      .enter()
      .append('g')
      .attr('class', this.classForNode)
      .attr('transform', () => {
        return `translate(${source.y0}, ${source.x0})`
      })

    const nodeUpdate = nodeEnter.merge(node)

    renderBaseNodes({
      node,
      nodeEnter,
      nodeUpdate,
      nodeWidth: store.nodeWidth,
      iconWidth: store.iconWidth,
      nodeHeight: store.nodeHeight,
      collapsedHeight: store.nodeSizes.collapsed.height,
      duration,
    })

    // render node info and controls
    renderNodes({
      node,
      duration,
      nodeEnter,
      nodeUpdate,
      nodeWidth: store.nodeWidth,
      iconWidth: store.iconWidth,
      nodeHeight: store.nodeHeight,
      collapsedHeight: store.nodeSizes.collapsed.height,
      addOneChild: this.addOneChild.bind(this),
      addAllChildren: this.addAllChildren.bind(this),
      removeOneChild: this.removeOneChild.bind(this),
      collapseNode: this.collapseNode.bind(this),
      nodeActions: this.nodeActions.bind(this),
      nodeOptions: this.nodeOptions,
      svg: this.svg,
    })

    //for rendering group temp -- before saved
    renderBuildGroup({
      node,
      nodeEnter,
      nodeUpdate,
      nodeWidth: store.nodeWidth,
      iconWidth: store.iconWidth,
      nodeHeight: store.nodeHeight,
      collapsedHeight: store.nodeSizes.collapsed.height,
      nodeColor: graphColors[0],
    })

    //render highlighted flow
    renderFlows({
      data,
      flow,
      flowSvg: this.flowSvg,
      nodeWidth: store.nodeWidth,
      iconWidth: store.iconWidth,
      nodeHeight: store.nodeHeight,
      collapsedHeight: store.nodeSizes.collapsed.height,
      nodeActions: this.nodeActions.bind(this),
    })

    renderLinks({
      svg: this.svg,
      links: links,
      maxPathWidth: this.maxPathWidth,
      maxWeight: this.maxWeight,
      duration: duration,
      source: source,
      height: this.height,
      selectedPath: store.selectedPath,
      linkActions: this.linkActions,
    })

    DropShadowRenderer({
      svg: this.svg,
      d3Selection: '.baseNode',
    })

    renderLabels({
      nodeEnter,
      nodeUpdate,
    })

    // Store the old positions for transition.
    nodes.forEach(d => {
      d.x0 = d.x
      d.y0 = d.y
    })

    this.diagramState = this.cloneD3Data(data)
    renderGroups(this.groupsSvg)
  }

  //////
  classForNode(d) {
    let className = `node node${d.depth} nodeId_${d.id}`
    if (d.hiddenChildren) className += 'expand'
    className += d.data.activities.length > 1 ? ' _collapsedN' : ' _activityN'
    if (subprocessStore.subprocessMode || store.groups.groupsMode) className += ' draggable'
    return className
  }

  linkActions = link => {
    store.setSelectedPathByNode(link, false)
  }

  /////
  nodeActions(event, d) {
    if (d.data.type === 'start') return

    store.setViewingNode(d)
    this.selectNode(d)

    store.showDetails(event)
  }

  getMaxDepth(node) {
    const maxArr = []
    this.getMaxDepthRecursive(node, maxArr)

    const max = Math.max(...maxArr)
    return max
  }

  getMaxDepthRecursive(node, maxArr) {
    maxArr.push(node.depth)

    if (node.children) {
      node.children.forEach(child => this.getMaxDepthRecursive(child, maxArr))
    }
  }

  buildGraph(node, nodeIds) {
    const eventGraph = {}
    eventGraph.activities = node.activities.map(activity => {
      return {
        signature: activity.signature,
      }
    })

    eventGraph.children = node.children.map(childNode => {
      if (nodeIds.length === 0) {
        return null
      }

      if (childNode.id === nodeIds[0]) {
        const ids = [...nodeIds]
        ids.shift()
        return this.buildGraph(childNode, ids)
      }
      return null
    })

    eventGraph.children = eventGraph.children.filter(c => c)

    return eventGraph
  }

  highlightSimilar(d) {
    this.highlightSimilarRecursive(store.d3Data, d.data.name)
    this.update()
  }

  highlightSimilarRecursive(node, name) {
    node.highlight = node.data.name === name

    if (node.children) {
      node.children.forEach(child => this.highlightSimilarRecursive(child, name))
    }
    if (node.hiddenChildren) {
      node.hiddenChildren.forEach(child => this.highlightSimilarRecursive(child, name))
    }
  }

  toggleRequired(d) {
    d.isRequired = !d.isRequired
    this.update(d)
  }

  addOneChild(d) {
    d.hiddenChildren = d.hiddenChildren || []
    d.hiddenChildren = d.hiddenChildren.sort((a, b) => b.value - a.value)
    const child = d.hiddenChildren.shift()
    d.children = d.children || []

    if (!child) return

    if (d.hiddenChildren.length === 0) {
      d.hiddenChildren = null
    }

    d.children.push(child)
    d.children = d.children.sort((a, b) => b.value - a.value)

    //must collapse all children of new child showing
    this.collapsed[child.data.id] = { collapsed: false, node: child }
    this.collapse(child)
    this.update(d)
    store.setViewingNode(d)
    store.unhideGroups()
    store.setCollapsed({ ...this.collapsed })
  }

  addAllChildren(d) {
    let children = d.children || []
    children = children.concat(d.hiddenChildren || [])
    d.hiddenChildren = null

    if (children.length === 0) {
      return
    }
    d.children = d.children || []

    d.children = children.filter(obj => obj !== null && typeof obj !== 'undefined').sort((a, b) => b.value - a.value)

    d.children.forEach(child => {
      this.collapsed[child.data.id] = { collapsed: false, node: child }
      this.expandAllRecursive(child)
    })
    this.update(d)
    store.setViewingNode(d)
    store.unhideGroups()
    store.setCollapsed({ ...this.collapsed })
  }

  expandAllRecursive(d) {
    const children = d.children || []

    d.children = children
      .concat(d.hiddenChildren)
      .filter(o => o !== null && typeof o !== 'undefined')
      .sort((a, b) => b.value - a.value)

    if (d.children.length === 0) {
      d.children = null
    }
    d.hiddenChildren = null

    if (d.children) {
      d.children.forEach(child => {
        this.collapsed[child.data.id] = { collapsed: false, node: child }
        this.expandAllRecursive(child)
      })
      store.setCollapsed({ ...this.collapsed })
    }
  }

  removeOneChild(d) {
    const child = d.children.pop()
    if (d.children.length === 0) {
      d.children = null
    }

    d.hiddenChildren = d.hiddenChildren || []
    d.hiddenChildren.unshift(child)
    this.collapsed[child.data.id] = { collapsed: true, node: child }

    this.update(d)
    store.hideGroups()
    store.setViewingNode(d)
    store.setCollapsed({ ...this.collapsed })
  }

  collapseNode(d) {
    const { parent } = d
    if (!parent.children) return
    const toHide = parent.children.find(o => o.data.id === d.data.id)
    parent.hiddenChildren = parent.hiddenChildren || []

    parent.hiddenChildren.push(toHide)
    parent.children = parent.children.filter(obj => {
      return obj.data.id !== d.data.id
    })

    if (parent.children.length === 0) {
      parent.children = null
    }

    this.collapsed[d.data.id] = { collapsed: true, node: d }
    d.viewing = false
    this.clearActivityPanel()
    this.update(parent)
    store.hideGroups()
    store.setCollapsed({ ...this.collapsed })
  }

  collapseAllChildren(d) {
    if (d.children) {
      d.hiddenChildren = d.children
      d.children = null
      this.update(d)
    }

    d.hiddenChildren.forEach(c => {
      this.collapsed[c.data.id] = { collapsed: true, node: c }
    })
    store.setViewingNode(d)
    store.hideGroups()
    store.setCollapsed({ ...this.collapsed })
  }

  handleNodeClick(node, d) {
    store.groups.handleNodeClick(node, d)
    this.update(d)
  }

  //clone d3 data
  cloneD3Data(d) {
    const newData = cloneDeep(d.data)
    return newData
  }

  collapse(d) {
    if (d.children) {
      d.hiddenChildren = d.hiddenChildren || []
      d.hiddenChildren = d.hiddenChildren.concat(d.children)
      if (d.hiddenChildren.length === 0) {
        d.hiddenChildren = null
      }

      if (d.hiddenChildren) {
        d.hiddenChildren.forEach(child => {
          this.collapsed[child.data.id] = { collapsed: true, node: child }
          this.collapse(child)
        })
        store.setCollapsed({ ...this.collapsed })
      }
      d.children = null
    }
    store.hideGroups()
  }

  //set flows and then renders all flows with all settings
  setFlows(value, index) {
    if (store.flowData.length === 0) return
    let nodesToShow = []

    // take all flow data and get union of nodes
    for (let i = 0; i <= index; i += 1) {
      nodesToShow = concat(nodesToShow, toJS(store.flowData[i].path))
    }
    nodesToShow = unionWith(nodesToShow)

    //use collapsed list (this.collapsed) to see if anything has already been collapsed
    //we want already collapsed nodes to remain collapsed
    const collapsed = []
    Object.keys(this.collapsed).forEach(k => {
      if (this.collapsed[k].collapsed) collapsed.push(+k)
    })
    nodesToShow = nodesToShow.filter(f => {
      return collapsed.indexOf(f) < 0
    })

    //then rerender
    this.showFlows(store.d3Data, nodesToShow)
    this.update(store.d3Data)

    //hide hidden group and reveal ones that show up due to any expansion
    store.hideGroups()
    store.unhideGroups()
  }

  showFlows(node, nodesToShow) {
    const { parent } = node
    if (parent) {
      parent.children = parent.children || []
      parent.hiddenChildren = parent.hiddenChildren || []
      const i = nodesToShow.findIndex(f => node.data.id === f)

      //not found
      if (i === -1 && node.data.type !== 'decision') {
        parent.hiddenChildren = parent.hiddenChildren.filter(c => c.data.id !== node.data.id)
        parent.hiddenChildren.push(node)
        parent.children = parent.children.filter(c => c.data.id !== node.data.id)
        if (parent.children && parent.children.length === 0) {
          parent.children = null
        }
        return
      } else {
        parent.children = parent.children.filter(c => c.data.id !== node.data.id)
        parent.children.push(node)
        parent.hiddenChildren = parent.hiddenChildren.filter(c => c.data.id !== node.data.id)
      }

      if (parent.hiddenChildren && parent.hiddenChildren.length === 0) {
        parent.hiddenChildren = null
      }
    }

    if (node.children) {
      node.children.forEach(child => this.showFlows(child, nodesToShow))
    }
    if (node.hiddenChildren) {
      node.hiddenChildren.forEach(child => this.showFlows(child, nodesToShow))
    }
  }
}
