import React, { useEffect, useState } from 'react'
import { string, func } from 'prop-types'
import { Button, theme, useForm, Spin } from '@fortressiq/fiq-ds'
import cloneDeep from 'lodash/cloneDeep'

import { useUserState } from 'context/UserContext'
import FormPage from './FormPage'
import FormFooter from './FormFooter'

import formPagesTemplate, { nestedForms, coeDropdownAttributes, attributesMap } from './formPages'

import { fetchFormQuestions, postCoeIntakeForm } from './coeApis'

const CoeForm = ({ specName, autopilotUUID, removeModal }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [invalidPermissions, setInvalidPermissions] = useState(false)
  const [coeFormQuestions, setCoeFormQuestions] = useState(cloneDeep(formPagesTemplate))
  const [pagesLength, setPagesLength] = useState(formPagesTemplate.length)

  const { email } = useUserState()

  const { control, formState, register, watch, getValues, setError, clearErrors } = useForm({
    defaultValues: {
      name: specName,
      Submitter_Email__c: email,
      description: '',
      pdd_name: specName,
      How_is_Process_Measured__c: '# of times performed',
    },
  })

  // generate form question labels, field types,  and attributes
  useEffect(() => {
    generateFormQuestions()
  }, [])

  useEffect(() => {
    if (coeFormQuestions) setPagesLength(coeFormQuestions.length)
  }, [coeFormQuestions])

  const generateFormPages = (program, dropdowns) => {
    if (!program?.data?.app?.Program) {
      setInvalidPermissions(true)
      setLoading(false)
      return
    }
    const newFormPages = cloneDeep(formPagesTemplate)
    const { descendants, id, name, ...coeProgram } = program.data.app.Program
    Object.entries(coeProgram).forEach(([key, value]) => {
      // keys from the api do not match the attribute keys, need to map them
      const [attributeType, attributeNumber] = key.split(/(\d+)/)
      const attribute = `${attributesMap[attributeType]}_${attributeNumber}__c`

      const pageIndex = newFormPages.findIndex(page => page.attribute === attributeType)
      if (pageIndex) {
        const fieldType = coeDropdownAttributes.find(attr => attr === attribute) ? 'select' : 'input'
        const options = getOptions(fieldType, dropdowns, attribute)
        if (!newFormPages[pageIndex].questions) newFormPages[pageIndex].questions = []

        if (value) {
          newFormPages[pageIndex].questions.push({
            attribute: attribute,
            label: value,
            type: fieldType,
            options: options,
          })
        }
      }
    })

    // has a different dropdown source than the others?
    const workstreamSelect = newFormPages[0].questions.find(question => question.attribute === 'Workstream_Path__c')
    workstreamSelect.options = descendants.nodes.map(option => ({ label: option.name, value: option.id }))

    const formPageQuestions = newFormPages.filter(page => page.questions.length > 0)
    return formPageQuestions
  }

  const getOptions = (fieldType, dropdowns, attribute) => {
    let options = null
    if (fieldType === 'select') {
      const optionsValue = dropdowns.data.app.template.attributes.find(attr => attr.apiName === attribute)
      if (optionsValue) {
        options = optionsValue.pickListValues
          .filter(value => value.value)
          .map(option => ({ label: option.name, value: option.name }))
      }
    }
    return options
  }

  const generateFormQuestions = () => {
    setLoading(true)
    const formQuestions = fetchFormQuestions()
    formQuestions
      .then(([program, dropdowns]) => {
        const formPageQuestions = generateFormPages(program, dropdowns)
        setCoeFormQuestions(formPageQuestions)
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const isValidateFormPage = newPage => {
    let filled = true
    const values = getValues()
    let i = newPage > currentPage ? newPage - 1 : currentPage
    const isFilled = (field, question) => {
      if (!field) {
        filled = false
        setError(question.attribute, { type: 'string', message: 'This field is required.' })
      }
    }
    while (i >= 0) {
      coeFormQuestions[i].questions.forEach(question => {
        isFilled(values[question.attribute], question)
      })
      i--
    }
    if (filled) clearErrors()
    return filled
  }

  const renderIndicators = () => {
    const indicators = []
    for (let i = 0; i < pagesLength; i += 1) {
      indicators.push({
        filled: i <= currentPage,
      })
    }
    return indicators
  }

  const setFormPageKeyDown = (event, newPage) => {
    if (event && event.keyCode !== 13) return
    setFormPage(newPage)
  }

  const setFormPage = newPage => {
    if (newPage > currentPage && !isValidateFormPage(newPage)) return

    if (newPage < 0) setCurrentPage(0)
    else if (newPage > pagesLength - 1) setCurrentPage(pagesLength - 1)
    else setCurrentPage(newPage)
  }

  // some selects change the form questions
  // on trigger a change to the form questions / format handler
  const onChangeFormTrigger = (page, triggerValue) => {
    const newCoeForm = cloneDeep(coeFormQuestions)

    if (!nestedForms[page][triggerValue]) return

    const newCoeFormPage = newCoeForm[page].questions.filter((question, i) => {
      return !question.nested
    })

    newCoeForm[page].questions = [...newCoeFormPage, ...nestedForms[page][triggerValue]]
    setCoeFormQuestions(newCoeForm)
  }

  const onSubmitCoe = e => {
    e.preventDefault()
    const formValues = getValues()
    if (!isValidateFormPage(formPagesTemplate.length)) return
    postCoeIntakeForm(formValues, autopilotUUID).then(result => {
      removeModal()
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          padding: theme['default-spacer-sm'],
          width: '100%',
          height: 'auto',
          backgroundColor: theme.white,
          border: 'none',
        }}
      >
        {loading && (
          <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin size={50} />
          </div>
        )}
        {!loading &&
          invalidPermissions &&
          'Unable to access the CoE Manager intake form. Please contact your Process Discovery admin.'}

        {!loading &&
          !invalidPermissions &&
          coeFormQuestions.map((page, i) => {
            return currentPage === i ? (
              <FormPage
                formState={formState}
                page={page.questions}
                pageIndex={currentPage}
                key={`page_${i}`}
                onChangeFormTrigger={onChangeFormTrigger}
                register={register}
                watch={watch}
                control={control}
                title={page.title}
                specName={specName}
              />
            ) : null
          })}

        {coeFormQuestions && (
          <FormFooter
            currentPage={currentPage}
            setFormPage={setFormPage}
            pagesLength={pagesLength}
            handleSubmit={onSubmitCoe}
          />
        )}
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '-45px',
          display: 'flex',
        }}
      >
        {coeFormQuestions &&
          renderIndicators().map((dot, i) => {
            return (
              <Button
                key={`dot${i}`}
                style={{
                  width: '20px',
                  height: '20px',
                  border: 'none',
                  borderRadius: '100%',
                  background: dot.filled ? theme['contrast-blue'] : theme.white,
                  margin: '0 10px',
                  padding: 0,
                }}
                onKeyDown={e => setFormPageKeyDown(e, i)}
                onClick={() => setFormPage(i)}
                aria-label={`page_indicator_${i}`}
                tabIndex={i}
              />
            )
          })}
      </div>
    </div>
  )
}

CoeForm.propTypes = {
  specName: string,
  autopilotUUID: string,
  removeModal: func,
}

export default CoeForm
