import React, { useEffect, useState } from 'react'
import { AdvancedCell, Button, Switch, DateCell } from '@fortressiq/fiq-ds'

import TrinityTable, { columnWidths } from 'components/TrinityTable'

import api from 'lib/Api'

import { useHeaderDispatch } from '../../header/HeaderContext'
import CreateTenantButton from './CreateTenantButton'

const pageTitle = 'All Tenants'

const sortKeyMap = {
  createdAt: 'created_at',
  appUrl: 'app_url',
  isActive: 'is_active',
}

const TenantIsActive = ({ isActive: isActiveDefault, tenantId, updateCellData }) => {
  const [isActive, setIsActive] = useState(isActiveDefault)
  const handleChange = ({ SWITCH_STATE: val }) => {
    api.put(`/tenants/${tenantId}`, { is_active: val })
    updateCellData(val)
    setIsActive(val)
  }

  useEffect(() => {
    setIsActive(isActiveDefault)
  }, [isActiveDefault])

  // TODO: Will need updating with fiq-ds@4.x.x
  return <Switch checked={isActive} onSwitch={handleChange} />
}

const TenantList = () => {
  const headerDispatch = useHeaderDispatch()

  useEffect(() => {
    headerDispatch({
      type: 'set',
      heading: pageTitle,
      title: pageTitle,
    })
    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      width: columnWidths.id,
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value }) => <AdvancedCell>{value}</AdvancedCell>,
    },
    {
      Header: 'Application URL',
      accessor: 'appUrl',
      Cell: ({ value }) => <AdvancedCell>{value}</AdvancedCell>,
    },
    {
      Header: 'UUID',
      accessor: 'uuid',
      Cell: ({ value }) => <AdvancedCell>{value}</AdvancedCell>,
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      width: columnWidths.date,
      Cell: ({ value }) => <DateCell timestamp={value} />,
    },
    {
      Header: 'Is Active',
      accessor: 'isActive',
      align: 'center',
      width: 40,
      minWidth: 40,
      Cell: ({
        value: isActive,
        row: {
          original: { id },
        },
        updateCellData,
      }) => <TenantIsActive tenantId={id} isActive={isActive} updateCellData={updateCellData} />,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      width: 80,
      disableSortBy: true,
      disableResizing: true,
      align: 'center',
      Cell: ({
        row: {
          original: { id },
        },
      }) => (
        <Button href={`/admin/tenants/${id}/edit`} size='small'>
          Edit
        </Button>
      ),
    },
  ]
  return (
    <TrinityTable
      sortKeyMap={sortKeyMap}
      toolbarActions={[<CreateTenantButton key='createTenant' />]}
      url='/tenants'
      dataKey='tenants'
      tableId='tenants-id'
      columns={columns}
    />
  )
}

export default TenantList
