import React, { Fragment, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { Button, ButtonGroup, Input, theme } from '@fortressiq/fiq-ds'
import { useGetSetState } from 'react-use'
import { observer } from 'mobx-react'

import store from '../store/DiagramComposerStore'

const SaveModal = ({ onClose, flowchartId, diagramName: defaultDiagramName }) => {
  const history = useHistory()
  const [get, setState] = useGetSetState({ name: defaultDiagramName })

  const save = async e => {
    e.preventDefault()
    if (!get().name && !defaultDiagramName) return

    const diagram = await store.saveDiagram(flowchartId, get().name || defaultDiagramName)
    onClose()
    history.replace(`/diagrams/${diagram.id}`)

    store.loadData(flowchartId)
  }

  useEffect(() => {
    if (flowchartId === 'new') {
      setState({ name: '' })
    }
  }, [flowchartId, setState])

  return (
    <Fragment>
      <Input
        defaultValue={get().name || defaultDiagramName}
        label='Name'
        id='name'
        onChange={({ target: { value } }) => setState({ name: value })}
        placeholder='New Process Specification Name'
        style={{ marginTop: theme['default-spacer-sm'] }}
      />
      <ButtonGroup style={{ display: 'block', marginTop: theme['default-spacer-md'], textAlign: 'right' }}>
        <Button disabled={get().name.length === 0} onClick={save} type='primary'>
          Save
        </Button>
      </ButtonGroup>
    </Fragment>
  )
}

export default observer(SaveModal)
