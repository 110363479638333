import React, { useEffect, useState, createContext, useRef, useCallback, useMemo } from 'react'
import api from 'lib/Api'
import { useParams, useHistory } from 'react-router-dom'
import qs from 'qs'

export const EventContext = createContext(null)
export const { Consumer } = EventContext

export const EventProvider = ({ children, location }) => {
  const { observerID, reviewID, eventAnnotationID } = useParams()

  const [ratio, setRatio] = useState(1)
  const [windowOffset, setWindowOffset] = useState([0, 0])
  const [maxX, setMaxX] = useState(0)
  const [maxY, setMaxY] = useState(0)

  const [systemBoundingBoxes, setSystemBoundingBoxes] = useState([])
  const [userBoundingBoxes, setUserBoundingBoxes] = useState([])
  const [screenshotUrl, setScreenshotUrl] = useState()
  const [newBoundingBox, setNewBoundingBox] = useState()
  const [mouseStartCords, setMouseStartCords] = useState([0, 0])
  const [isMouseDown, setIsMouseDown] = useState(false)

  const [activeBox, setActiveBox] = useState()
  const [isMoving, setIsMoving] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const [annotationsIds, setAnnotationsIds] = useState([])
  const [current, setCurrent] = useState()

  const [next, setNext] = useState()
  const [prev, setPrev] = useState()

  const [currentHover, setCurrentHover] = useState()

  const { appID } = qs.parse(location.search, { ignoreQueryPrefix: true })

  const history = useHistory()

  const createReview = useCallback(async () => {
    // create application review
    const {
      data: { id: newReviewID },
    } = await api.post('/application_reviews', { application_id: appID, observer_id: observerID })

    // get related event annotations
    const { data: eventAnnotations } = await api.get(`/application_reviews/${newReviewID}/event_annotations`)
    const [firstEventAnnotation] = eventAnnotations

    if (firstEventAnnotation) {
      // redirect to route for first event annotation in the new application review
      // use history.replace to prevent user going back to the '/new' route
      history.replace(`/review/${observerID}/${newReviewID}/${firstEventAnnotation.id}`)
    }
  }, [appID, observerID, history])

  const getAnnotations = useCallback(async () => {
    setIsLoading(true)
    const { data: eventAnnotations } = await api.get(`/application_reviews/${reviewID}/event_annotations`)
    setAnnotationsIds(eventAnnotations)
    if (eventAnnotationID) {
      const index = eventAnnotations.findIndex(obj => obj.id === parseInt(eventAnnotationID, 10))
      setPrev(eventAnnotations[index - 1])
      setNext(eventAnnotations[index + 1])
      setCurrent(index)
    }
  }, [reviewID, eventAnnotationID])

  const fetchEvent = useCallback(async () => {
    setIsLoading(true)
    const { data } = await api.get(`/application_reviews/${reviewID}/event_annotations/${eventAnnotationID}`)
    setSystemBoundingBoxes(
      Array.isArray(data.system_suggested_bounding_boxes) ? data.system_suggested_bounding_boxes : []
    )
    setUserBoundingBoxes(Array.isArray(data.user_suggested_bounding_boxes) ? data.user_suggested_bounding_boxes : [])
    setScreenshotUrl(data.screenshot_url)
    setIsLoading(false)
  }, [reviewID, eventAnnotationID])

  const updateEventAnnotation = async () => {
    setIsLoading(true)
    await api.put(`/application_reviews/${reviewID}/event_annotations/${eventAnnotationID}`, {
      system_suggested_bounding_boxes: systemBoundingBoxes,
      user_suggested_bounding_boxes: userBoundingBoxes,
    })
    if (next) {
      history.push(`/review/${observerID}/${reviewID}/${next.id}`)
    } else getAnnotations()
  }

  useEffect(() => {
    if (observerID && appID) {
      createReview()
    }

    if (reviewID && observerID) {
      getAnnotations()
    }

    if (eventAnnotationID && reviewID) {
      fetchEvent()
    }
  }, [observerID, appID, reviewID, eventAnnotationID, history, getAnnotations, fetchEvent, createReview])

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)

    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  const imageRef = useRef(null)

  const updateBox = (x1, y1, x2, y2) => {
    // Conditions: Swap x1 and x2 or y1 and y2 if user creates box from right to left or from bottom to top.
    const newX1 = x1 < x2 ? x1 : x2
    const newY1 = y1 < y2 ? y1 : y2
    const newX2 = x1 < x2 ? x2 : x1
    const newY2 = y1 < y2 ? y2 : y1
    // Conditions: Prevent box being made outside the image
    setNewBoundingBox({
      bounds: [
        newX1 >= 0 ? newX1 : 0,
        newY1 >= 0 ? newY1 : 0,
        newX2 <= maxX ? newX2 : maxX,
        newY2 <= maxY ? newY2 : maxY,
      ],
    })
  }

  const updateDimensions = () => {
    // Set ratio based on image naturalWidth and image width
    const { top, left, width } = imageRef.current.getBoundingClientRect()
    const { naturalWidth, naturalHeight } = imageRef.current
    setRatio(width / naturalWidth)
    setMaxX(naturalWidth)
    setMaxY(naturalHeight)
    setWindowOffset([left, top])
  }

  const onMouseDown = e => {
    e.preventDefault()
    const element = e.target
    if (element.dataset.direction) {
      updateDimensions()
      const { direction } = element.dataset
      updateBox(activeBox.bounds[0], activeBox.bounds[1], activeBox.bounds[2], activeBox.bounds[3])
      if (activeBox.boxType === 'systemBoundingBox') {
        const copy = [...systemBoundingBoxes]
        const index = systemBoundingBoxes.findIndex(box => box.bounds.toString() === activeBox.bounds.toString())
        copy[index].invalid = true
        setSystemBoundingBoxes(copy)
      } else {
        const copy = [...userBoundingBoxes]
        const index = userBoundingBoxes.findIndex(box => box.bounds.toString() === activeBox.bounds.toString())
        copy.splice(index, 1)
        setUserBoundingBoxes(copy)
      }
      if (direction === 'move') {
        setIsMoving(true)
        const { pageX, pageY } = e
        const x1 = (pageX - windowOffset[0]) / ratio
        const y1 = (pageY - windowOffset[1]) / ratio
        setMouseStartCords([x1, y1])
        setIsMouseDown(true)
      }
      if (direction === 'tl') {
        setMouseStartCords([activeBox.bounds[2], activeBox.bounds[3]])
      }
      if (direction === 'tr') {
        setMouseStartCords([activeBox.bounds[0], activeBox.bounds[3]])
      }
      if (direction === 'bl') {
        setMouseStartCords([activeBox.bounds[2], activeBox.bounds[1]])
      }
      if (direction === 'br') {
        setMouseStartCords([activeBox.bounds[0], activeBox.bounds[1]])
      }
      setIsMouseDown(true)
    }
    if (element === imageRef.current) {
      updateDimensions()
      setActiveBox()
      const { pageX, pageY } = e
      const x1 = (pageX - windowOffset[0]) / ratio
      const y1 = (pageY - windowOffset[1]) / ratio
      setMouseStartCords([x1, y1])
      setIsMouseDown(true)
    }
  }
  const onMouseMove = e => {
    e.preventDefault()
    if (!isMouseDown) return null
    const { clientX, clientY } = e
    const x = (clientX - windowOffset[0]) / ratio
    const y = (clientY - windowOffset[1]) / ratio
    if (isMoving) {
      const changeX = x - mouseStartCords[0]
      const changeY = y - mouseStartCords[1]
      updateBox(
        activeBox.bounds[0] + changeX,
        activeBox.bounds[1] + changeY,
        activeBox.bounds[2] + changeX,
        activeBox.bounds[3] + changeY
      )
    } else {
      updateBox(mouseStartCords[0], mouseStartCords[1], x, y)
    }
  }
  const onMouseUp = e => {
    e.preventDefault()
    if (newBoundingBox)
      if (newBoundingBox) {
        setUserBoundingBoxes([...userBoundingBoxes, newBoundingBox])
        setActiveBox({ bounds: [...newBoundingBox.bounds] })
      }
    setNewBoundingBox()
    setIsMouseDown(false)
    setIsMoving(false)
  }

  const onActiveBox = (i, bounds, boxType) => {
    setActiveBox({ bounds: [...bounds], boxType })
  }

  const deleteBoundingBox = (i, boxType) => {
    if (boxType === 'userBoundingBox') {
      const copy = [...userBoundingBoxes]
      copy[i].invalid = true
      copy.splice(i, 1)
      setUserBoundingBoxes(copy)
    }
    if (boxType === 'systemBoundingBox') {
      const copy = [...systemBoundingBoxes]
      copy[i].invalid = true

      setSystemBoundingBoxes(copy)
    }
  }

  const onSaveAnnotation = async () => {
    await updateEventAnnotation()
    setIsLoading(false)
  }

  const toggleHover = bounds => {
    setCurrentHover(bounds && bounds.toString())
  }

  const value = useMemo(() => ({
    systemBoundingBoxes,
    ratio,
    activeBox,
    newBoundingBox,
    userBoundingBoxes,
    isLoading,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onActiveBox,
    deleteBoundingBox,
    imageRef,
    updateDimensions,
    onSaveAnnotation,
    observerID,
    reviewID,
    screenshotUrl,
    annotationsIds,
    toggleHover,
    currentHover,
    current,
    next,
    prev,
  }))

  return <EventContext.Provider value={value}>{children}</EventContext.Provider>
}
