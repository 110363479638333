import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useNotification, Button, Col, Input, Row } from '@fortressiq/fiq-ds'

import api from 'lib/Api'
import Main from 'components/main/Main'

import { useHeaderDispatch } from '../../header/HeaderContext'
import { gutter, topMarginStyle } from '../common/constants'
import SwitchSection from './SwitchSection'

const FormRowDouble = ({ left, right }) => (
  <Row gutter={gutter} type='grid' noOfCols={2} style={topMarginStyle}>
    <Col>{left}</Col>
    <Col>{right}</Col>
  </Row>
)

const FormRowSingle = ({ children }) => (
  <Row gutter={gutter} type='grid' noOfCols={1} style={topMarginStyle}>
    <Col>{children}</Col>
  </Row>
)

const FormHeader = ({ onChange, tenantName }) => (
  <FormRowSingle>
    <Input label='Tenant name' name='tenant_name' onChange={onChange} value={tenantName} />
  </FormRowSingle>
)

const pageTitle = 'Create a Tenant'
const defaultFormState = {
  tenant_name: '',
  username: '',
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  is_lite_customer: false,
}

const TenantCreator = () => {
  const { addNotification } = useNotification()
  const history = useHistory()
  const [formState, setFormState] = useState(defaultFormState)
  const handleChange = ({ target: { name, value } }) => setFormState({ ...formState, [name]: value })
  const onSwitchFiqLite = ({ SWITCH_STATE }) => setFormState({ ...formState, is_lite_customer: SWITCH_STATE })
  const headerDispatch = useHeaderDispatch()
  const { tenant_name: tenantName, username, email, password, first_name: firstName, last_name: lastName } = formState

  useEffect(() => {
    headerDispatch({
      type: 'set',
      heading: pageTitle,
      title: pageTitle,
    })

    return () => {
      headerDispatch({
        type: 'clear',
      })
    }
  }, [headerDispatch])

  const onSave = async () => {
    const { data } = await api.post('/tenants', formState)
    if (data.ok) {
      addNotification({
        description: `${formState.tenant_name} successfully created.`,
        message: 'Success',
        type: 'success',
      })
    }
    history.push('/admin/tenants')
  }

  return (
    <Main>
      <FormHeader onChange={handleChange} tenantName={tenantName} />
      <FormRowSingle>
        <Input label='Admin username' name='username' value={username} type='text' onChange={handleChange} />
      </FormRowSingle>
      <FormRowDouble
        left={<Input label='Admin email' name='email' value={email} type='email' onChange={handleChange} />}
        right={
          <Input label='Admin password' name='password' value={password} type='password' onChange={handleChange} />
        }
      />
      <FormRowDouble
        left={<Input label='Admin first name' name='first_name' value={firstName} onChange={handleChange} />}
        right={<Input label='Admin last name' name='last_name' value={lastName} onChange={handleChange} />}
      />
      <SwitchSection label='FIQ Lite' id='fiqLiteSwitch' onSwitch={onSwitchFiqLite} />
      <Row gutter={gutter} type='flex' noOfCols={1} style={topMarginStyle}>
        <Col>
          <Button onClick={onSave} type='secondary'>
            Save
          </Button>
        </Col>
      </Row>
    </Main>
  )
}

export default TenantCreator
